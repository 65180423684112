import React from 'react';
import classNames from 'classnames';

const MenuButton = ({ label, openState, count, onClick }) => {
  const renderButtonCount = () => {
    if (count) {
      return (
        <div className="menu-button__count" data-cart-count={count}></div>
      );
    }

    return null;
  }

  return (
    <button
      className={classNames('menu-button', {'menu-button--open': openState})}
      onClick={onClick}
    >
      <div className="menu-button__inner">{label}</div>
      {renderButtonCount()}
    </button>
  );
}

export default MenuButton;
