import React, { Component } from 'react';
import classNames from 'classnames';
import chroma from 'chroma-js';

class SwatchButton extends Component {
  isLightSwatch() {
    if (chroma(this.props.color).luminance() > 0.8) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <div className={ classNames('swatch-button', {'swatch-button--light': this.isLightSwatch()}) }>
        <input
          type="radio"
          name={this.props.swatchType}
          id={`${this.props.swatchType}-${this.props.color}`}
        />
        <label
          htmlFor={`${this.props.swatchType}-${this.props.color}`}
          style={{ backgroundColor: this.props.color }}
        />
      </div>
    );
  }
}

export default SwatchButton;
