import React from 'react';
import SwatchButton from './SwatchButton';

const SwatchButtons = ({ swatchType }) => {
  const swatches = [
    '#ffffff',
    '#000000',
    '#B8BCBF',
    '#17498C',
    '#FC667D',
    '#548655',
    '#D96E51',
    '#B12232',
    '#836653',
    '#D2C3AD',
  ];

  const renderSwatchButtons = swatches.map((swatch) =>
    <SwatchButton
      color={swatch}
      key={swatch}
      swatchType={swatchType}
    />
  );

  return (
    <div className="swatch-buttons">
      {renderSwatchButtons}
    </div>
  );
}

export default SwatchButtons;


