import React, { Component } from 'react';
import classNames from 'classnames';

import MenuButton from './MenuButton';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedMenu: false
    }

    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
  }

  handleMenuButtonClick() {
    this.setState({ expandedMenu: !this.state.expandedMenu });
  }

  render() {
    return (
      <header className={classNames('site-header', {'site-header--expanded': this.state.expandedMenu})}>
        <div className="site-header__main">
          <a href="/" className="site-header__logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 17">
              <path d="M113.68 3.28c-.57 0-1.02.14-1.35.41-.33.27-.5.63-.5 1.08 0 .45.2.8.6 1.07.41.26 1.34.57 2.8.93 1.46.36 2.6.9 3.4 1.62.8.72 1.2 1.77 1.2 3.14 0 1.38-.5 2.5-1.54 3.36a6.18 6.18 0 0 1-4.08 1.29c-2.44 0-4.63-.9-6.58-2.71l2.05-2.5c1.65 1.44 3.19 2.17 4.6 2.17.62 0 1.12-.14 1.48-.4.36-.28.54-.64.54-1.1 0-.47-.2-.84-.57-1.1a7.46 7.46 0 0 0-2.27-.83c-1.79-.43-3.1-.98-3.93-1.66-.83-.69-1.24-1.76-1.24-3.22 0-1.45.52-2.58 1.57-3.37A6.33 6.33 0 0 1 113.8.27c1.03 0 2.05.18 3.08.53 1.03.35 1.92.85 2.68 1.5l-1.73 2.5a6.77 6.77 0 0 0-4.14-1.52zM100.82 3.6V16h-3.44V3.6h-4.35V.61h12.14V3.6zM85.9.62h3.44V16h-3.43l-7.33-9.64V16h-3.43V.62h3.21l7.55 9.9zM66.75.62h3.43V16h-3.43zM62.05 5.73c0 2.46-.97 4.05-2.92 4.77l3.89 5.5H58.8l-3.41-4.9H53V16h-3.43V.62h5.83c2.39 0 4.1.4 5.11 1.21 1.02.8 1.53 2.1 1.53 3.9zm-6.53 2.4c1.17 0 1.97-.2 2.4-.58.42-.38.63-.98.63-1.81 0-.83-.22-1.4-.66-1.7-.44-.32-1.2-.47-2.3-.47H53v4.55h2.5zM38.94.62c2.36 0 4.08.46 5.16 1.38 1.07.91 1.61 2.32 1.61 4.22s-.55 3.29-1.66 4.17c-1.1.88-2.8 1.32-5.07 1.32h-2.04V16H33.5V.62h5.44zm.3 8.12c1.15 0 1.93-.23 2.34-.7.4-.46.61-1.13.61-2.02 0-.88-.26-1.51-.8-1.89a4.41 4.41 0 0 0-2.5-.56h-1.95v5.17h2.3zM27.09 13.89a7.92 7.92 0 0 1-5.75 2.26 7.9 7.9 0 0 1-8.07-7.94A7.9 7.9 0 0 1 21.34.27a7.9 7.9 0 0 1 8.08 7.94c0 2.26-.78 4.15-2.33 5.68zm-1.17-5.66c0-1.37-.44-2.55-1.32-3.52a4.22 4.22 0 0 0-3.24-1.45c-1.29 0-2.37.49-3.25 1.45a5.03 5.03 0 0 0-1.32 3.51 5 5 0 0 0 1.32 3.5 4.23 4.23 0 0 0 3.25 1.44c1.28 0 2.36-.48 3.24-1.44a4.98 4.98 0 0 0 1.32-3.49zM.9 16V.62h3.42v12.32h6.56V16z"/>
            </svg>
          </a>
          <MenuButton
            label="menu"
            openState={this.state.expandedMenu}
            onClick={this.handleMenuButtonClick}
            count={1}
          />
        </div>
        <div className="site-header__nav-wrapper">
          <nav className="site-nav">
            <ul>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/cart">Cart</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
